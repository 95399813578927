import React from 'react';

const SecurityTXTPage = () => {
    return (
        <div>
            <p>
            # For security issues related to Amazon Web Services (AWS), please see our security policy<br/>
            Policy: https://aws.amazon.com/security/vulnerability-reporting/<br/>
            <br/>
            # To contact AWS regarding a vulnerability<br/>
            Contact: mailto:aws-security@amazon.com<br/>
            Preferred-Languages: en<br/>
            <br/>
            # We support PGP encryption<br/>
            Encryption: https://aws.amazon.com/security/aws-pgp-public-key/<br/>
            <br/>
            # This file expires every 365 days<br/>
            Expires: 2023-12-31T18:37:07z<br/>
            <br/>
            # We're hiring - join Amazon Security!<br/>
            Hiring: https://www.amazon.jobs/en/business_categories/amazon-security<br/>
            </p>
        </div>
    );
};

export { SecurityTXTPage };



