import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Amplify, Auth, Hub } from "aws-amplify";
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';

import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { UploadFilesPage } from './pages/uploadFiles';
import { StatsPage } from './pages/stats';
import { SecurityTXTPage } from './pages/security';
import { AboutPage } from './pages/about';
import { API, Storage, Analytics } from "aws-amplify";
import { listCaptionCornerModels } from "./graphql/queries";
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  TableEmptyState,
  TableNoMatchState,
} from './commons/common-components';

import { COLUMN_DEFINITIONS } from './homepagecolumns';
import { useColumnWidths } from './commons/use-column-widths';
import {
  CustomAppLayout,
  Navigation,
  Notifications,
} from './commons/common-components';
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";

import logo from './images/AWS_logo.png';
import './styles/landing-page.scss';
import awsconfig from "./aws-exports.js";

Analytics.autoTrack('session', {
  // REQUIRED, turn on/off the auto tracking
  enable: true
});

Analytics.autoTrack('pageView', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: 'IndexPage',
  type: 'SPA',
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  }
});

// URLs for testing locally and on Amplify
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);

const utilitiesConst = [
  // {
  //   type: 'button',
  //   iconName: 'notification',
  //   ariaLabel: 'Notifications',
  //   badge: true,
  //   disableUtilityCollapse: true,
  // },
  // { type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings' },

  {
    type: "button",
    text: "Stats",
    href: "/stats",
  },
  {
    iconName: 'settings',
    type: "button",
    text: "Support",
    href: "https://t.corp.amazon.com/create/templates/b6638cca-a8c2-4d13-83e1-d263fd66f829",
  },
  {
    iconName: 'contact',
    type: "button",
    text: "Feedback",
    href: "mailto:captioncorner@amazon.com?subject=Caption%20Corner%20Feedback",

  }];


// Variables to store Username and Email
var username = '';
var email = '';

// const profileActions = [
//   { type: 'button', id: 'documentation', text: 'Profile' },
//   { type: 'button', id: 'preferences', text: 'Preferences' },
//   { type: 'button', id: 'security', text: 'Security' },
//   {
//     id: 'documentation',
//     text: 'Documentation',
//     href: '#',
//     external: true,
//     externalIconAriaLabel: ' (opens in new tab)',
//   },
//   { id: 'support', text: 'Support' },
//   { id: 'feedback', text: 'Feedback', href: '#', external: true, externalIconAriaLabel: ' (opens in new tab)' },
//   {
//     type: 'menu-dropdown',
//     id: 'support-group',
//     text: 'Support',
//     items: [
//       {
//         id: 'documentation',
//         text: 'Documentation',
//         href: '#',
//         external: true,
//         externalIconAriaLabel: ' (opens in new tab)',
//       },
//       { id: 'feedback', text: 'Feedback', href: '#', external: true, externalIconAriaLabel: ' (opens in new tab)' },
//       { id: 'support', text: 'Customer support' },
//     ],
//   },
//   { type: 'button', id: 'signout', text: 'Sign out'},
// ];


const Content = () => {
  return (
    <Box margin={{ bottom: 'l' }}>
      <div className="custom-home__header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { colspan: { l: 8, xxs: 10 }, offset: { l: 2, xxs: 1 } },
              { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
              { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
            ]}
          >
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              <span className="custom-home__category"></span>
            </Box>
            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                Caption Corner
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                Generate captions/subtitles for your videos
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-home__header-sub-title">
                  Caption Corner is a self-service tool to generate captions in SRT or VTT formats. It's powered by Amazon Transcribe which automatically converts speech to text. How cool is that?!
                </span>
              </Box>
            </div>
            <div className="custom-home__header-cta">
              <Container>
                <SpaceBetween size="xl">
                  <Box variant="h2" padding="n">
                    Get started here, {username}
                    {/* ({email}) */}
                  </Box>
                  <Box>
                    Valid file formats: MP3, MP4, WAV, FLAC, AMR, OGG, and WebM.
                  </Box>
                  <Button href="/uploadfiles" variant="primary">
                    Upload file(s)
                  </Button>
                </SpaceBetween>
              </Container>
            </div>
          </Grid>
        </Box>
      </div>
      <div>
        <ListOfVideos />
      </div>
    </Box>
  );
};

//Get Login content if user is not signed
const LoginContent = () => {
  return (
    <>
      <div className="custom-home__header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
              { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
            ]}
          >
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              <span className="custom-home__category">I can haz webpage?</span>
            </Box>
            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                Oops!
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                Looks like you stumbled upon an internal Amazon site
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-home__header-sub-title">
                  If you're lost you can close this window. There's nothing to see here.
                </span>
              </Box>
            </div>
            <div className="custom-home__header-cta">
              <Container>
                <SpaceBetween size="xl">
                  <Box variant="h2" padding="n">
                    Amazon employees
                  </Box>
                  <Box>
                    You may use this button to log in.
                  </Box>
                  <Button variant="primary" onClick={() => Auth.federatedSignIn({ customProvider: "FederateOIDC", })}>Login</Button>
                </SpaceBetween>
              </Container>
            </div>
          </Grid>
        </Box>
      </div>
    </>
  );
};

function UploadFilesContent() {
  return <UploadFilesPage />;
}

function UploadFiles() {
  //Set initial side nav state: open or closed
  const [navigationOpen, setNavigationOpen] = React.useState(true);

  //Set initial side nav state: open or closed
  return (<CustomAppLayout
    disableContentPaddings={true}
    content={<UploadFilesContent />}
    navigation={<Navigation activeHref="#/" />}
    navigationOpen={navigationOpen}
    onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
    toolsHide={true}
    notifications={<Notifications />}
  />)
}

function StatsContent() {
  return <StatsPage />;
}

function Stats() {
  //Set initial side nav state: open or closed
  const [navigationOpen, setNavigationOpen] = React.useState(true);
  return (<CustomAppLayout
    disableContentPaddings={true}
    content={<StatsContent />}
    navigation={<Navigation activeHref="#/" />}
    navigationOpen={navigationOpen}
    onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
    toolsHide={true}
    notifications={<Notifications />}
  />)
}


function Homepage() {
  //Set initial side nav state: open or closed
  const [navigationOpen, setNavigationOpen] = React.useState(true);
  const [currentPath, setCurrentPath] = useState(undefined);
  return (<CustomAppLayout
    disableContentPaddings={true}
    content={<Content />}
    navigation={<Navigation onNavigate={(e) => setCurrentPath(e.pathname)} />}
    navigationOpen={navigationOpen}
    onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
    toolsHide={true}
    notifications={<Notifications />}
  />)
}

async function getURLToDownloadFile(key) {
  // console.log("KEY: " + key);
  const signedURL = await Storage.get(key, { level: 'private' });
  // console.log(signedURL);
  return signedURL;
}

// Function to setup the table for our list of files that the user has uploaded
function ListOfVideos() {
  const [columnDefinitions, saveWidths] = useColumnWidths('React-Table-Widths', COLUMN_DEFINITIONS);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [filesToDisplay, setFilesToDisplay] = useState([]);
  const [filesToDisplayLength, setFilesToDisplayLength] = useState(0);
  // const [filteringText, setFilteringText] = React.useState("");
  // console.log(columnDefinitions[0]);
  const { items, actions, filterProps, paginationProps } = useCollection(
    filesToDisplay,
    {
      filtering: {
        empty: <TableEmptyState resourceName="Files" />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: { pageSize: 10 },
      sorting: {
        defaultState: {
          sortingColumn: columnDefinitions[0],
          isDescending: true
        }
      },
      // selection: {},
    }
  );

  useEffect(() => {
    getfilesFromDB()
  }, [])

  // Need to plug in pagination
  async function getfilesFromDB() {

    try {

      // const allCaptionCornerModels = await DataStore.query(CaptionCornerModel, Predicates.ALL, {
      //   sort: (s) => s.createDate(SortDirection.ASCENDING)
      // });
      const allCaptionCornerModels = await API.graphql({
        query: listCaptionCornerModels,

      });

      const files = allCaptionCornerModels.data.listCaptionCornerModels.items;
      // Get signed URLS for SRT and VTT files
      for (var i = 0; i < files.length; i++) {
        // console.log((files[i].createDate));
        if (files[i].srt) {
          // console.log(files[i].srt);
          files[i].srt = await getURLToDownloadFile(files[i].srt);
          // console.log(files[i].srt);
        }
        if (files[i].vtt) {
          // console.log(files[i].vtt);
          files[i].vtt = await getURLToDownloadFile(files[i].vtt);
          // console.log(files[i].vtt);
        }
      }
      // console.log(files);
      setFilesToDisplay(files)
      // console.log("SIZE " + files.length);
      setFilesToDisplayLength(files.length);

    } catch (err) { console.log(err) }
  }

  return (

    <Table
      // onSelectionChange={({ detail }) =>
      //   setSelectedItems(detail.selectedItems)
      // }
      // selectedItems={selectedItems}
      // ariaLabels={{
      //   selectionGroupLabel: "Items selection",
      //   allItemsSelectionLabel: ({ selectedItems }) =>
      //     `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
      //     } selected`,
      //   itemSelectionLabel: ({ selectedItems }, item) =>
      //     item.name
      // }}
      columnDefinitions={columnDefinitions}
      columnDisplay={[
        { id: "createDate", visible: false },
        { id: "name", visible: true },
        { id: "filename", visible: true },
        { id: "statusMessage", visible: true },
        { id: "srt", visible: true },
        { id: "vtt", visible: true },
        { id: "languageToDisplay", visible: true },
        { id: "description", visible: true },
        { id: "costSavings", visible: true },
        // { id: "createdate", visible: true },
        // { id: "delete", visible: true }
      ]}
      items={items}
      loadingText="Loading files"
      // selectionType="multi"
      // trackBy="name"
      // resizableColumns={true}
      // onColumnWidthsChange={saveWidths}
      // ariaLabels={distributionTableAriaLabels}
      // renderAriaLive={renderAriaLive}
      stickyHeader={true}
      // stickyColumns={true}
      stripedRows={true}
      wrapLines
      empty={
        <Box
          margin={{ vertical: "xs" }}
          textAlign="center"
          color="inherit"
        >
          <SpaceBetween size="m">
            <b>You haven't processed any files yet</b>
            <Button href="/uploadfiles" variant="primary">
              Upload file(s)
            </Button>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter
          {...filterProps}
          // filteringText={filteringText}
          filteringPlaceholder="Find files"
          filteringAriaLabel="Filter files"
        // onChange={({ detail }) => {
        //   setFilteringText(detail.filteringText);
        //   // console.log("ONCHANGE");
        // }
        // }
        />
      }

      header={
        <Header
          counter={
            selectedItems.length
              ? "(" + selectedItems.length + "/" + JSON.stringify(filesToDisplayLength) + ")"
              : "(" + JSON.stringify(filesToDisplayLength) + ")"
          }
        // actions={
        //   <SpaceBetween
        //     direction="horizontal"
        //     size="xs"
        //   >
        //     {/* <ButtonDropdown
        //       items={[
        //         {
        //           text: "Download SRTs",
        //           id: "downloadSRTs",
        //           disabled: false
        //         },
        //         {
        //           text: "Download VTTs",
        //           id: "downloadVTTs",
        //           disabled: false
        //         },
        //         {
        //           text: "Delete",
        //           id: "deleteFiles",
        //           disabled: false
        //         }
        //       ]}
        //     >
        //       Actions
        //     </ButtonDropdown> */}
        //     {/* <Button>Download SRTs & VTTs</Button> */}
        //     {/* <Button>Delete</Button> */}
        //   </SpaceBetween>
        // }
        >
          Files that you've previously processed
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
    // preferences={
    //   <CollectionPreferences
    //     title="Preferences"
    //     confirmLabel="Confirm"
    //     cancelLabel="Cancel"
    //     preferences={{
    //       pageSize: 10,
    //       contentDisplay: [
    //         { id: "filename", visible: true },
    //         { id: "value", visible: true },
    //         { id: "type", visible: true },
    //         { id: "description", visible: true }
    //       ]
    //     }}
    //     pageSizePreference={{
    //       title: "Page size",
    //       options: [
    //         { value: 10, label: "10 resources" },
    //         { value: 20, label: "20 resources" }
    //       ]
    //     }}
    //     wrapLinesPreference={{}}
    //     stripedRowsPreference={{}}
    //     contentDensityPreference={{}}
    //     contentDisplayPreference={{
    //       options: [
    //         {
    //           id: "filename",
    //           label: "File name",
    //           alwaysVisible: true
    //         },
    //         { id: "value", label: "Text value" },
    //         { id: "type", label: "Type" },
    //         { id: "description", label: "Description" }
    //       ]
    //     }}
    //     stickyColumnsPreference={{
    //       firstColumns: {
    //         title: "Stick first column(s)",
    //         description:
    //           "Keep the first column(s) visible while horizontally scrolling the table content.",
    //         options: [
    //           { label: "None", value: 0 },
    //           { label: "First column", value: 1 },
    //           { label: "First two columns", value: 2 }
    //         ]
    //       },
    //       lastColumns: {
    //         title: "Stick last column",
    //         description:
    //           "Keep the last column visible while horizontally scrolling the table content.",
    //         options: [
    //           { label: "None", value: 0 },
    //           { label: "Last column", value: 1 }
    //         ]
    //       }
    //     }}
    //   />
    // }
    />
  );
}

function App() {
  const [user, setUser] = useState(null);
  // getUser function
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }
  // Use effect for auth
  useEffect((user) => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          // console.log(event);
          // console.log(data);
          getUser().then((userData) => setUser(userData));
          // console.log(user);
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
          // console.log("Sign in failure", data);
          break;
      }
    });
    getUser().then((userData) => setUser(userData));
  }, []);

  // Set the username and email for use later
  if (user) {
    // console.log(user.signInUserSession.idToken.payload.given_name);
    // console.log(user.signInUserSession.idToken.payload.email);
    username = user.signInUserSession.idToken.payload.given_name;
    email = user.signInUserSession.idToken.payload.email;
  }
  else { }
  return (
    <>
      {username ? (
        <>
          <Router>
            <TopNavigation
              // i18nStrings={i18nStrings}
              identity={{
                href: awsconfig.oauth.redirectSignIn,
                title: 'Caption Corner',
                logo: { src: logo, alt: 'Caption Corner logo' },
              }}
              // search={
              //   <Input
              //     ariaLabel="Input field"
              //     clearAriaLabel="Clear"
              //     value={searchValue}
              //     type="search"
              //     placeholder="Search"
              //     onChange={({ detail }) => setSearchValue(detail.value)}
              //   />
              // }
              utilities={username ? (utilitiesConst) : ([
                // {
                //   type: 'button',
                //   iconName: 'notification',
                //   ariaLabel: 'Notifications',
                //   badge: true,
                //   disableUtilityCollapse: true,
                // },
                // { type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings' },
                // {
                //   type: 'menu-dropdown',
                //   text: 'Customer name',
                //   description: 'customer@example.com',
                //   iconName: 'user-profile',
                //   items: profileActions,
                // },
              ])}
            />
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/uploadfiles" element={<UploadFiles />} />
              <Route path="/.well-known/security.txt" element={<SecurityTXTPage />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>
          </Router>
        </>
      ) : (<LoginContent />)}
    </>
  );
}

// Kek this adds Cloudscape's 3.0 Visual Refresh to the page
document.body.className = "awsui-visual-refresh";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);