import { TableProps } from '@amzn/awsui-components-react';

export function createTableSortLabelFn(
    column: TableProps.ColumnDefinition<unknown>
  ): TableProps.ColumnDefinition<unknown>['ariaLabel'] {
    if (!column.sortingField && !column.sortingComparator && !column.ariaLabel) {
      // console.log("HERE");
      return;
    }
    return ({ sorted, descending }) => {
      // console.log("SORTING");
      return `${column.header}, ${sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted'}.`;
    };
  }
