/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCaptionCornerModel = /* GraphQL */ `
  query GetCaptionCornerModel($id: ID!) {
    getCaptionCornerModel(id: $id) {
      id
      name
      type
      description
      filename
      s3Location
      statusMessage
      statusType
      srt
      vtt
      language
      languageToDisplay
      costSavings
      createDate
      updateDate
      delete
      duration
      extension
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCaptionCornerModels = /* GraphQL */ `
  query ListCaptionCornerModels(
    $filter: ModelCaptionCornerModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaptionCornerModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        description
        filename
        s3Location
        statusMessage
        statusType
        srt
        vtt
        language
        languageToDisplay
        costSavings
        createDate
        updateDate
        delete
        duration
        extension
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cappysByDate = /* GraphQL */ `
  query CappysByDate(
    $type: String!
    $createDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaptionCornerModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cappysByDate(
      type: $type
      createDate: $createDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        description
        filename
        s3Location
        statusMessage
        statusType
        srt
        vtt
        language
        languageToDisplay
        costSavings
        createDate
        updateDate
        delete
        duration
        extension
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCaptionCornerModels = /* GraphQL */ `
  query SearchCaptionCornerModels(
    $filter: SearchableCaptionCornerModelFilterInput
    $sort: [SearchableCaptionCornerModelSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCaptionCornerModelAggregationInput]
  ) {
    searchCaptionCornerModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        type
        description
        filename
        s3Location
        statusMessage
        statusType
        srt
        vtt
        language
        languageToDisplay
        costSavings
        createDate
        updateDate
        delete
        duration
        extension
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCaptionCornerStatsModel = /* GraphQL */ `
  query GetCaptionCornerStatsModel($id: ID!) {
    getCaptionCornerStatsModel(id: $id) {
      id
      totalCostSavings
      totalDuration
      totalFiles
      createDate
      updateDate
      totalmp3
      totalmp4
      totalwav
      totalflac
      totalogg
      totalamr
      totalwebm
      username
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCaptionCornerStatsModels = /* GraphQL */ `
  query ListCaptionCornerStatsModels(
    $filter: ModelCaptionCornerStatsModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaptionCornerStatsModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        totalCostSavings
        totalDuration
        totalFiles
        createDate
        updateDate
        totalmp3
        totalmp4
        totalwav
        totalflac
        totalogg
        totalamr
        totalwebm
        username
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const captionCornerStatsModelsByUsername = /* GraphQL */ `
  query CaptionCornerStatsModelsByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCaptionCornerStatsModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    captionCornerStatsModelsByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        totalCostSavings
        totalDuration
        totalFiles
        createDate
        updateDate
        totalmp3
        totalmp4
        totalwav
        totalflac
        totalogg
        totalamr
        totalwebm
        username
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCaptionCornerStatsModels = /* GraphQL */ `
  query SearchCaptionCornerStatsModels(
    $filter: SearchableCaptionCornerStatsModelFilterInput
    $sort: [SearchableCaptionCornerStatsModelSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCaptionCornerStatsModelAggregationInput]
  ) {
    searchCaptionCornerStatsModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        totalCostSavings
        totalDuration
        totalFiles
        createDate
        updateDate
        totalmp3
        totalmp4
        totalwav
        totalflac
        totalogg
        totalamr
        totalwebm
        username
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
