export const navigationBreadcrumbs = [
  {
    text: 'Caption Corner',
    href: '/',
  },
  {
    text: 'Navigation',
    href: '/',
  },
];

export const uploadFilesBreadcrumbs = [
  ...navigationBreadcrumbs,
  {
    text: 'Upload File(s)',
    href: '/uploadfiles',
  },
];

export const statsBreadcrumbs = [
  ...navigationBreadcrumbs,
  {
    text: 'Stats',
    href: '/stats',
  },
];

