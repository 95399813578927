import React, { useRef, useState, useEffect } from 'react';

import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import Button from '@amzn/awsui-components-react/polaris/button';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Grid from '@amzn/awsui-components-react/polaris/grid';

import '@amzn/awsui-global-styles/dark-mode-utils.css';
import '../styles/base.scss';

import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { statsBreadcrumbs } from './uploadfilescommon/breadcrumbs';
import { DashboardHeader, DashboardMainInfo } from './statscomponents/header';
import { CustomAppLayout } from '../commons/common-components';
import { Notifications, HelpPanelProvider } from '../commons';
import { BaseStaticWidget } from './statswidgets/base-static-widget';
import { fileTypesProcessed } from './statswidgets/file-types-processed';
import { siteUsers } from './statswidgets/site-users';


import { PieChart } from '@amzn/awsui-components-react';
import { colorChartsStatusPositive, colorChartsStatusHigh } from '@amzn/awsui-design-tokens';
import { percentageFormatter } from './statswidgets/chart-commons';

import { Box, Header, ColumnLayout } from '@amzn/awsui-components-react';
import { WidgetConfig } from './statswidgets/interfaces';
import { searchCaptionCornerModels, searchCaptionCornerStatsModels, listCaptionCornerStatsModels, getCaptionCornerStatsModel, captionCornerStatsModelsByUsername } from "../graphql/queries";
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { DataStore } from '@aws-amplify/datastore'
import { Analytics, Auth } from "aws-amplify";
import CaptionCornerModelCreateForm from '../ui-components/CaptionCornerModelCreateForm';
import { createCaptionCornerStatsModel } from '../graphql/mutations';

// import { AmplifyApiGraphQlResourceStackTemplate } from '@aws-amplify/cli-extensibility-helper';

// export function override(resources: AmplifyApiGraphQlResourceStackTemplate) {

//     resources.opensearch.OpenSearchDomain.encryptionAtRestOptions['enabled'] = true;
//     resources.opensearch.OpenSearchDomain.encryptionAtRestOptions['kmsKeyId'] = "<KMS ARN>";
// }

Analytics.autoTrack('pageView', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the event name, by default is 'pageView'
    eventName: 'StatsPage',
    type: 'SPA',
    getUrl: () => {
        // the default function
        return window.location.origin + window.location.pathname;
    }
});


const costSavings: WidgetConfig = {
    definition: { defaultRowSpan: 2, defaultColumnSpan: 1 },
    data: {
        icon: 'list',
        title: 'Cost Savings',
        description: 'Your Cost Savings',
        header: CostSavingsHeader,
        content: CostSavingsContent,
    },
};

function CostSavingsHeader() {
    return (
        <Header variant="h2" description="What you've done">
            Your Stats
        </Header>
    );
}

function CostSavingsContent() {
    const [costSavingsTotalAmount, setCostSavingsTotalAmount] = useState(0);
    const [filesProcessedUserTotal, setFilesProcessedUserTotal] = useState(0);

    useEffect(() => {
        getFromDDB();
    }, [])

    async function getFromDDB() {
        // Get the User
        var email = "";
        await Auth.currentAuthenticatedUser().then((user) => email = user.attributes.email);

        const emailsplit = email.split("@");
        const allCaptionCornerStatsModels = await API.graphql({
            query: listCaptionCornerStatsModels,
            variables: {
                filter: {
                    username: {
                        eq: emailsplit[0]
                    }
                }
            }
        });

        // console.log(allCaptionCornerStatsModels);
        // The Stats entry exists
        if (allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.items.length > 0) {
            // console.log(allCaptionCornerStatsModels.data.searchCaptionCornerStatsModels.items[0].totalCostSavings);
            setFilesProcessedUserTotal(allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.items[0].totalFiles);
            setCostSavingsTotalAmount(allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.items[0].totalCostSavings);
        }
    }
    return (
        <>
            <ColumnLayout columns={2} variant="text-grid" minColumnWidth={10}>
                <div>
                    <Box variant="awsui-key-label">Cost Savings</Box>
                    <Box color="text-status-success" fontSize="display-l" fontWeight="bold" >
                        ${costSavingsTotalAmount}
                    </Box>
                </div>
                <div>
                    <Box variant="awsui-key-label">Files Processed</Box>
                    <Box color="text-status-info" fontSize="display-l" fontWeight="bold" >
                        {filesProcessedUserTotal}
                    </Box>
                </div>
            </ColumnLayout>
            <br />
            <Box textAlign="center">
                Based on a cost of $2 per minute
            </Box>
        </>
    );
}

const Breadcrumbs = () => (
    <BreadcrumbGroup items={statsBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const statsOverview: WidgetConfig = {
    definition: { defaultRowSpan: 2, defaultColumnSpan: 3 },
    data: {
        icon: 'list',
        title: 'Stats overview',
        description: 'Overview of Caption Corner',
        header: StatsOverviewHeader,
        content: StatsOverviewWidget,
    },
};

function StatsOverviewHeader() {
    return (
        <Header variant="h2" description="Global Stats">
            Caption Corner Overview
        </Header>
    );
}

function StatsOverviewWidget() {
    const [globalFilesProcessed, setGlobalFilesProcessed] = useState(0);
    const [globalCostSavings, setGlobalCostSavings] = useState(0);
    const [globalUsers, setGlobalUsers] = useState(0);

    useEffect(() => {
        getTotalFilesProcessedFromDDB();
        // getTotalUsers();

    }, [])

    // async function getTotalUsers() {

    //     let apiName = 'AdminQueries';
    //     let path = '/listUsers';
    //     let myInit = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    //         }
    //     }
    //     const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    //     console.log(rest);
    //     setTotalFilesProcessed(0);
    // }


    async function getTotalFilesProcessedFromDDB() {
        // await API.graphql(graphqlOperation(searchCaptionCornerModels, {input: todo}));
        // const oneEvent = await API.graphql(allCaptionCornerModels(GetEvent, { id: 'some id' }))


        const allCaptionCornerStatsModels = await API.graphql({
            query: listCaptionCornerStatsModels,
            // variables: {
            //     aggregates: {
            //         type: 'sum',
            //         field: 'totalCostSavings',
            //         name: 'costSavingsSum',
            //     }
            // }
            // authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
            // variables: {
            //     items: {
            //         totalCostSavings: "*"
            //     }
            // }
        });

        var GlobalFilesProcessed = 0;
        var GlobalCostSavings = 0
        //One loop to calculate GlobalFilesProcessed GlobalCostSavings
        for (var i = 0; i < allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.items.length; i++) {
            GlobalCostSavings = GlobalCostSavings + allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.items[i].totalCostSavings;
            GlobalFilesProcessed = GlobalFilesProcessed + allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.items[i].totalFiles;
        }


        // Making two queries here hmmmmmm
        // const allCaptionCornerStatsModelsTotalFiles = await API.graphql({
        //     query: listCaptionCornerStatsModels,
        //     variables: {
        //         aggregates: {
        //             type: 'sum',
        //             field: 'totalFiles',
        //             name: 'totalFilesSum',
        //         }
        //     }
        //     // authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        //     // variables: {
        //     //     items: {
        //     //         totalCostSavings: "*"
        //     //     }
        //     // }
        // });


        // const allCaptionCornerStatsModels = await API.graphql({
        //     query: searchCaptionCornerStatsModels,
        //     // variables: {
        //     //     items: {
        //     //         name: '*'
        //     //     }
        //     // },
        //     //TODO: Fix once bug is resolved

        //     authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        //     variables: {
        //         // aggregates: {
        //         //     type: 'sum',
        //         //     field: 'totalCostSavings',
        //         //     name: 'costSavingsSum'
        //         // }
        //     }


        // });
        // console.log(allCaptionCornerStatsModels);
        // console.log(allCaptionCornerStatsModelsTotalFiles);
        // console.log(allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.aggregateItems[0])
        // console.log(allCaptionCornerStatsModelsTotalFiles.data.listCaptionCornerStatsModels.aggregateItems[0].result.value)
        // console.log(allCaptionCornerStatsModelsTotalFiles.data.listCaptionCornerStatsModels.aggregateItems[0].result.value)

        // console.log("HERE " + JSON.stringify(allCaptionCornerStatsModels, null, 2));
        // setGlobalFilesProcessed(allCaptionCornerStatsModelsTotalFiles.data.listCaptionCornerStatsModels.aggregateItems[0].result.value);
        // setGlobalCostSavings(allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.aggregateItems[0].result.value);
        setGlobalFilesProcessed(GlobalFilesProcessed);
        setGlobalCostSavings(GlobalCostSavings);
        setGlobalUsers(allCaptionCornerStatsModels.data.listCaptionCornerStatsModels.items.length);
    }

    return (
        <ColumnLayout columns={3} variant="text-grid" minColumnWidth={170}>
            <div>
                <Box variant="awsui-key-label">Global cost savings</Box>
                <Box color="text-status-success" fontSize="display-l" fontWeight="bold" >
                    ${globalCostSavings}
                </Box>
            </div>
            <div>
                <Box variant="awsui-key-label">Global files processed</Box>
                <Box color="text-status-info" fontSize="display-l" fontWeight="bold" >
                    {globalFilesProcessed}
                </Box>
            </div>
            <div>
                <Box variant="awsui-key-label">Global website users</Box>
                <Box color="text-status-info" fontSize="display-l" fontWeight="bold" >
                    {globalUsers}
                </Box>
            </div>
            {/* <div>
          <Box variant="awsui-key-label">Load balancers</Box>
          <Link variant="awsui-value-large" href="#">
            28
          </Link>
        </div> */}
        </ColumnLayout>
    );
}

const typesOfFiles: WidgetConfig = {
    definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
    data: {
        icon: 'pieChart',
        title: 'Files Processed',
        description: 'Types of files',
        header: TypesOfFilesHeader,
        content: TypesOfFilesContent,
        staticMinHeight: 450,
    },
};

function TypesOfFilesHeader() {
    return (
        <Header variant="h2">
            Your Processed Files
        </Header>
    );
}

function TypesOfFilesContent() {

    const [filesProcessedMP4s, setFilesProcessedMP4s] = useState(0);
    const [filesProcessedMP3s, setFilesProcessedMP3s] = useState(0);
    const [filesProcessedWavs, setFilesProcessedWavs] = useState(0);
    const [filesProcessedFlacs, setFilesProcessedFlacs] = useState(0);
    const [filesProcessedOggs, setFilesProcessedOggs] = useState(0);
    const [filesProcessedAmrs, setFilesProcessedAmrs] = useState(0);
    const [filesProcessedWebms, setFilesProcessedWebms] = useState(0);


    useEffect(() => {
        getFilesProcessed();
    }, [])

    async function getFilesProcessed() {
        var email = "";
        await Auth.currentAuthenticatedUser().then((user) => email = user.attributes.email);
        const emailsplit = email.split("@");

        // console.log(emailsplit);
        // Check if the DDB entry for the user exists in the Stats table. If not create it.
        const allCaptionCornerStatsModels = await API.graphql({
            query: captionCornerStatsModelsByUsername,
            variables: {
                username: emailsplit[0]
                //   sortDirection: 'DESC'
                //   // limit: 2
                // filter: {
                //     username: {
                //         eq: emailsplit[0]
                //     }
                // }
            }
        });
        // console.log(allCaptionCornerStatsModels);
        if (allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items.length > 0) {
            setFilesProcessedMP4s(allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items[0].totalmp4);
            setFilesProcessedMP3s(allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items[0].totalmp3);
            setFilesProcessedWavs(allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items[0].totalwav);
            setFilesProcessedFlacs(allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items[0].totalflac);
            setFilesProcessedOggs(allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items[0].totalogg);
            setFilesProcessedAmrs(allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items[0].totalamr);
            setFilesProcessedWebms(allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items[0].totalwebm);
        }
    }
    return (
        <PieChart
            hideFilter={true}
            fitHeight={true}
            size="small"
            data={[
                { title: 'MP4', value: filesProcessedMP4s },
                { title: 'MP3', value: filesProcessedMP3s },
                { title: 'WAV', value: filesProcessedWavs },
                { title: 'FLAC', value: filesProcessedFlacs },
                { title: 'OGG', value: filesProcessedOggs },
                { title: 'AMR', value: filesProcessedAmrs },
                { title: 'WEBM', value: filesProcessedWebms },
            ]}
            ariaLabel="Files Processed chart"
            ariaDescription="Pie chart summarizing the file types processed"
            segmentDescription={(datum, sum) => datum.value + (datum.value == 1 ? ' file' : ' files') + ', ' + percentageFormatter(datum.value / sum)}
            detailPopoverContent={(datum, sum) => [
                {
                    key: 'Files Processed',
                    value: datum.value,
                },
                {
                    key: 'Percentage',
                    value: percentageFormatter(datum.value / sum),
                },
            ]}
        // i18nStrings={{
        //     filterLabel: 'Filter displayed data',
        //     filterPlaceholder: 'Filter data',
        //     filterSelectedAriaLabel: 'selected',
        //     detailPopoverDismissAriaLabel: 'Dismiss',

        //     legendAriaLabel: 'Legend',
        //     chartAriaRoleDescription: 'pie chart',
        //     segmentAriaRoleDescription: 'segment',
        // }}
        />
    );
}


function Content() {
    return (
        <Grid
            gridDefinition={[
                { colspan: { l: 4, m: 4, default: 12 } },
                { colspan: { l: 8, m: 8, default: 12 } },
                { colspan: { l: 6, m: 6, default: 12 } },
                { colspan: { l: 6, m: 6, default: 12 } },
                { colspan: { l: 6, m: 6, default: 12 } },
                { colspan: { l: 6, m: 6, default: 12 } },
                { colspan: { l: 6, m: 6, default: 12 } },
                { colspan: { l: 6, m: 6, default: 12 } },
                { colspan: { l: 8, m: 8, default: 12 } },
                { colspan: { l: 4, m: 4, default: 12 } },
            ]}
        >
            {[
                costSavings,
                statsOverview,
                typesOfFiles,
                // fileTypesProcessed,
                // siteUsers,
                // alarms,
                // instanceLimits,
                // events,

                // featuresSpotlight,
                // accountAttributes,
            ].map((widget, index) => (
                <BaseStaticWidget key={index} config={widget.data} />
            ))}
        </Grid>
    );
}

const StatsPage = () => {
    // const [toolsOpen, setToolsOpen] = useState(false);
    // const [toolsContent, setToolsContent] = useState<React.ReactNode>(() => <DashboardMainInfo />);
    const appLayout = useRef<AppLayoutProps.Ref>(null);

    const handleToolsContentChange = (content: React.ReactNode) => {
        // setToolsOpen(true);
        // setToolsContent(content);
        // appLayout.current?.focusToolsClose();
    };

    return (
        <HelpPanelProvider value={handleToolsContentChange}>
            <CustomAppLayout
                ref={appLayout}
                content={
                    <ContentLayout header={<DashboardHeader actions={<Button variant="primary"></Button>} />}>
                        <Content />
                    </ContentLayout>
                }
                navigationHide={true}
                toolsHide={true}
                breadcrumbs={<Breadcrumbs />}
                // navigation={<DashboardSideNavigation />}
                // tools={toolsContent}
                // toolsOpen={toolsOpen}
                // onToolsChange={({ detail }) => setToolsOpen(detail.open)}
                notifications={<Notifications />}
            />
        </HelpPanelProvider>
    );
};

export { StatsPage };