import React from 'react';
import { Analytics } from "aws-amplify";
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Link from '@amzn/awsui-components-react/polaris/link';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Select from '@amzn/awsui-components-react/polaris/select';
import {
    CustomAppLayout,
    ExternalLink,
    ExternalLinkGroup,
    Navigation,
    Notifications,
    SeparatedList,
} from '../commons/common-components';

import mainLogo from './faqcomponents/capdiagram.png';

Analytics.autoTrack('pageView', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the event name, by default is 'pageView'
    eventName: 'FAQPage',
    type: 'SPA',
    getUrl: () => {
        // the default function
        return window.location.origin + window.location.pathname;
    }
});


const Content = () => {
    return (
        <>
            <Box margin={{ bottom: 'l' }}>
                <div className="custom-home__header">
                    <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
                        <Grid
                            gridDefinition={[
                                { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                                { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                                { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
                            ]}
                        >
                            <Box fontWeight="light" padding={{ top: 'xs' }}>
                                <span className="custom-home__category"></span>
                            </Box>
                            <div className="custom-home__header-title">
                                <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                                    Caption Corner
                                </Box>
                                <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                                    Generate captions/subtitles for your videos
                                </Box>
                                <Box variant="p" fontWeight="light">
                                    <span className="custom-home__header-sub-title">
                                        Caption Corner is a self-service tool to generate captions in SRT or VTT formats. It's powered by Amazon Transcribe which automatically converts speech to text. How cool is that?!
                                    </span>
                                </Box>
                            </div>
                            <Container header={<Header variant="h2">Pricing</Header>}>
                                <SeparatedList
                                    ariaLabel="Pricing details"
                                    items={[
                                        <>
                                            <span>Individual</span>
                                            <Box variant="span" color="text-body-secondary">
                                                $0
                                            </Box>
                                        </>,
                                        <>
                                            <span>Team</span>
                                            <Box variant="span" color="text-body-secondary">
                                                $0
                                            </Box>
                                        </>,
                                        <>
                                            <span>non-Amazonians (External)</span>
                                            <Box variant="span" color="text-body-secondary">
                                                Not supported
                                            </Box>
                                        </>,
                                    ]}
                                />
                            </Container>
                        </Grid>
                    </Box>
                </div>

                <Box padding={{ top: 's', horizontal: 's' }}>


                    <Grid
                        gridDefinition={[
                            { colspan: { l: 8, m: 8, default: 12 }, offset: { l: 2, xxs: 1 } },
                        ]}
                    >
                        <SpaceBetween size="xxl">
                            <div>
                                <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                                    How it works
                                </Box>
                                {/* <Container>
                                    <div>
                                        <img src={mainLogo} alt="Service Diagram" height="410" />
                                    </div>
                                </Container> */}
                                <Container
                                    media={{
                                        content: (
                                            <img
                                                src={mainLogo}
                                                alt="Service Diagram"
                                            />
                                        ),
                                        height: 520,
                                        position: "top"
                                    }}
                                >
                                    
                                </Container>
                            </div>
                            <div>
                                <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                                    Use cases
                                </Box>
                                <Container>
                                    <ColumnLayout columns={2} variant="text-grid">
                                        <div>
                                            <Box variant="h3" padding={{ top: 'n' }}>
                                                Caption your videos
                                            </Box>
                                            <Box variant="p">
                                                Upload your videos and automatically generate captions for them with timestamps. No need for an external vendor or seperate program that requires a licence.
                                            </Box>
                                        </div>
                                        <div>
                                            <Box variant="h3" padding={{ top: 'n' }}>
                                                Caption your audio files
                                            </Box>
                                            <Box variant="p">
                                                Upload your audio files and automatically generate captions for them with timestamps.
                                            </Box>
                                        </div>
                                    </ColumnLayout>
                                </Container>
                            </div>
                            <div>
                                <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                                    Benefits and features
                                </Box>
                                <Container>
                                    <ColumnLayout columns={2} variant="text-grid">
                                        <div>
                                            <Box variant="h3" padding={{ top: 'n' }}>
                                                Custom AWS dictionary
                                            </Box>
                                            <Box variant="p">
                                                We use a custom dictionary that is populated with all AWS Service names as of 10/07/2023. If you need a new name added let us know!
                                            </Box>
                                        </div>
                                        <div>
                                            <Box variant="h3" padding={{ top: 'n' }}>
                                                Support for common media types
                                            </Box>
                                            <Box variant="p">
                                                We support MP3, MP4, WAV, FLAC, AMR, OGG, and WebM formats.
                                            </Box>
                                        </div>
                                        <div>
                                            <Box variant="h3" padding={{ top: 'n' }}>
                                                Reporting and analytics
                                            </Box>
                                            <Box variant="p">
                                                We offer a Statistics page to calculate cost savings for you as a user and for global usage.
                                            </Box>
                                        </div>
                                        <div>
                                            <Box variant="h3" padding={{ top: 'n' }}>
                                                Easy-to-use Interface
                                            </Box>
                                            <Box variant="p">
                                                Upload your files. Wait for them to be processed. Download your SRT and VTT files and off you go!
                                            </Box>
                                        </div>
                                    </ColumnLayout>
                                </Container>
                            </div>
                            <Container header={<Header variant="h2">Related services</Header>}>
                                <ColumnLayout columns={2} variant="text-grid">
                                    <div>
                                        <Box variant="h3" padding={{ top: 'n' }}>
                                            <ExternalLink fontSize="heading-m" variant="primary" href="https://aws.amazon.com/transcribe/">
                                                Amazon Transcribe
                                            </ExternalLink>
                                        </Box>
                                        <Box variant="p">
                                            Transcribe audio and video files
                                        </Box>
                                    </div>
                                    <div>
                                        <Box variant="h3" padding={{ top: 'n' }}>
                                            <ExternalLink fontSize="heading-m" variant="primary" href="https://aws.amazon.com/amplify/">
                                                AWS Amplify
                                            </ExternalLink>
                                        </Box>
                                        <Box variant="p">
                                            Build full-stack web and mobile apps in hours
                                        </Box>
                                    </div>
                                </ColumnLayout>
                            </Container>
                        </SpaceBetween>
                    </Grid>
                    <Grid
                        gridDefinition={[
                            { colspan: { l: 8, m: 8, default: 12 }, offset: { l: 2, xxs: 1 } },
                        ]}
                    >
                        <div>
                            <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                                Frequently Asked Questions
                            </Box>
                            <Container>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        How are cost savings calculated?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        We settled on a cost of $2 (USD) per minute of transcription after talking to various stakeholders, vendors and researching online.
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        Are my media files stored on this site permanently?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        No. Once captions are generated, media files are deleted from the Amazon S3 bucket automatically. We only retain associated SRT, VTT and JSON files and not the actual media itself.
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        What if a new AWS Service is released that is not in your dictionary?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        We try to keep the dictionary up-to-date but if it's missing let us know and we'll add it!
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        What AWS Services were used to build this site?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        AWS Amplify, Amazon Transcribe, Amazon S3, AWS AppSync, AWS Lambda, Amazon DDB, Amazon EventBridge, Amazon Cognito, Amazon Route 53 AWS CodeCommit, AWS CodeArtifact
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        What authentication method does this site use for Single Sign-On?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        Amazon Federate with Midway
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        What theme does the site use?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        Cloudscape (internal version)
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        What do I do if I have a feature request or bug I found?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        Click that Support link on the top right and let us know!
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        What do I do if I feedback?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        Click that Feedback link on the top right and let us know!
                                    </Box>
                                </div>

                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        Do you have a PRFAQ?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        Yep. Linked <a href="https://amazon.awsapps.com/workdocs-preview/index.html#/document/08e5b095ba0bfd2a21efa31f9c94767aaef63a32654176e819a2fb894a281763">here</a>.
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        Has this site been through an AppSec approval?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        It's currently in the process of going through an Appsec/ProdSec review.
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        How did you get a custom internal domain name?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        We used SuperNova
                                    </Box>
                                </div>
                                <div>
                                    <Box variant="h3" padding={{ top: 'n' }}>
                                        Who built this site?
                                    </Box>
                                    <Box color="text-status-info" variant="p" padding={{ bottom: 's' }}>
                                        That guy from LevelUp
                                    </Box>
                                </div>
                            </Container>
                        </div>
                        <div></div>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

const AboutPage = () => {
    const [navigationOpen, setNavigationOpen] = React.useState(true);
    return (
        <CustomAppLayout
            disableContentPaddings={true}
            content={<Content />}
            navigation={<Navigation activeHref="#/" />}
            navigationOpen={navigationOpen}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            toolsHide={true}
            notifications={<Notifications />}
        />
    );
};




export { AboutPage };