import { createTableSortLabelFn } from './columnsfunctions';
import Link from '@amzn/awsui-components-react/polaris/link';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Box from '@amzn/awsui-components-react/polaris/box';

const rawColumns = [
  {
    id: "createDate",
    header: "Created Date",
    cell: item => item.createDate,
    sortingField: "createDate",
    // minWidth: 100,
  },
  {
    // minWidth: "10",
    id: "name",
    header: "Name",
    cell: item => item.name,
    // sortingField: "name",
    minWidth: 100,
  },
  {
    id: "filename",
    header: "File name",
    cell: item => item.filename,
    // sortingField: "filename",
    minWidth: 100,
  },
  {
    id: "statusMessage",
    header: "Status",
    //3 statuses catered for thus far: in-progress, error and success
    cell: item => item.statusType ? <StatusIndicator type={item.statusType}>{item.statusMessage}</StatusIndicator> : <StatusIndicator >{item.statusMessage}</StatusIndicator>,
    // sortingField: "statusMessage",
    minWidth: 100,
  },
  {
    id: "srt",
    header: "SRT",
    // cell: item => item.srt ? <Button href={item.srt} variant="link">SRT</Button> : 'N/A',
    cell: item => item.srt ? <a href={item.srt} target="_blank" rel="noreferrer">SRT</a> : 'N/A',
    minWidth: 100,
  },
  {
    id: "vtt",
    header: "VTT",
    cell: item => item.vtt ? <a href={item.vtt} target="_blank" rel="noreferrer">VTT</a> : 'N/A',
    minWidth: 100,
  },
  {
    id: "languageToDisplay",
    header: "Language",
    cell: item => item.languageToDisplay,
    // sortingField: "languageToDisplay",
    minWidth: 100,
  },
  {
    id: "description",
    header: "Description",
    cell: item => item.description,
    minWidth: 100,
  },
  {
    id: "costSavings",
    header: "Cost Savings",
    cell: item => item.costSavings == 0 ? <Box textAlign="center" fontSize="body-m">N/A</Box> : <Box textAlign="center" color="text-status-success" fontSize="body-m" fontWeight="bold" >${item.costSavings}</Box>,
    // sortingField: "costSavings",
    minWidth: 100,
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));