import React from 'react';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
const navHeader = { text: 'Caption Corner', href: '/' };



export const navItems: SideNavigationProps['items'] = [
  {
    type: 'section',
    text: 'Navigation',
    items: [
      { type: 'link', text: 'Home', href: '/' },
      { type: 'link', text: 'Upload File(s)', href: '/uploadfiles' },
      { type: 'link', text: 'Stats', href: '/stats' },
      { type: 'link', text: 'About', href: '/about' },
    ],
  }
];

// const defaultOnFollowHandler: SideNavigationProps['onFollow'] = event => {

//   // keep the locked href for our demo pages
//   event.preventDefault();

// };

interface NavigationProps {
  activeHref?: string;
  header?: SideNavigationProps['header'];
  items?: SideNavigationProps['items'];
  onFollowHandler?: SideNavigationProps['onFollow'];
}

export function Navigation({
  activeHref,
  header = navHeader,
  items = navItems,
  // onFollowHandler = defaultOnFollowHandler,
}: NavigationProps) {
  let location = useLocation();
  const navigate = useNavigate()
  const [activeHref2, setActiveHref] = React.useState('/');

  return <SideNavigation items={items} header={header} activeHref={location.pathname} onFollow={(event) => {

    if (!event.detail.external) {
      event.preventDefault();
      setActiveHref(event.detail.href)
      navigate(event.detail.href)
    }
  }} />;
}
