import { API, Storage } from 'aws-amplify';
import { createCaptionCornerModel, createCaptionCornerStatsModel } from '../../graphql/mutations';
import { listCaptionCornerModels, getCaptionCornerModel, searchCaptionCornerStatsModels, captionCornerStatsModelsByUsername } from "../../graphql/queries";
import React, { useState, useEffect } from 'react';
import { Button, Form, SpaceBetween } from '@amzn/awsui-components-react';
import { useNavigate } from "react-router-dom"
import {
  Container,
  Header,
  Input,
  FormField,
  Select,
  FileUpload,
} from '@amzn/awsui-components-react';
import ProgressBar from "@amzn/awsui-components-react/polaris/progress-bar";
import { Auth } from "aws-amplify";

const LANGUAGE_OPTIONS = [
  { label: 'Afrikaans', value: 'af-ZA' },
  { label: 'Arabic, Gulf', value: 'ar-AE' },
  { label: 'Arabic, Modern Standard', value: 'ar-SA' },
  { label: 'Chinese, Simplified', value: 'zh-CN' },
  { label: 'Chinese, Traditional', value: 'zh-TW' },
  { label: 'Danish', value: 'da-DK' },
  { label: 'English, Australian', value: 'en-AU' },
  { label: 'English, British', value: 'en-GB' },
  { label: 'English, Indian', value: 'en-IN' },
  { label: 'English, Irish', value: 'en-IE' },
  { label: 'English, New Zealand', value: 'en-NZ' },
  { label: 'English, Scottish', value: 'en-AB' },
  { label: 'English, South African', value: 'en-ZA' },
  { label: 'English, US', value: 'en-US' },
  { label: 'English, Welsh', value: 'en-WL' },
  { label: 'French', value: 'fr-FR' },
  { label: 'French, Canadian', value: 'fr-CA' },
  { label: 'Farsi', value: 'fa-IR' },
  { label: 'German', value: 'de-DE' },
  { label: 'German, Swiss', value: 'de-CH' },
  { label: 'Hebrew', value: 'he-IL' },
  { label: 'Hindi, Indian', value: 'hi-IN' },
  { label: 'Indonesian', value: 'id-ID' },
  { label: 'Italian', value: 'it-IT' },
  { label: 'Japanese', value: 'ja-JP' },
  { label: 'Korean', value: 'ko-KR' },
  { label: 'Malay', value: 'ms-MY' },
  { label: 'Portuguese', value: 'pt-PT' },
  { label: 'Portuguese, Brazilian', value: 'pt-BR' },
  { label: 'Russian', value: 'ru-RU' },
  { label: 'Spanish', value: 'es-ES' },
  { label: 'Spanish, US', value: 'es-US' },
  { label: 'Swedish', value: 'sv-SE' },
  { label: 'Tamil', value: 'ta-IN' },
  { label: 'Telugu', value: 'te-IN' },
  { label: 'Thai', value: 'th-TH' },
  { label: 'Turkish', value: 'tr-TR' },
  { label: 'Vietnamese', value: 'vi-VN' },
];

// File formats: mp3 | mp4 | wav | flac | ogg | amr | webm
const SUPPORTED_FILE_FORMATS = [
  { label: 'MP3', value: 'mp3' },
  { label: 'MP4', value: 'mp4' },
  { label: 'WAV', value: 'wav' },
  { label: 'FLAC', value: 'flac' },
  { label: 'OGG', value: 'ogg' },
  { label: 'AMR', value: 'amr' },
  { label: 'WebM', value: 'webm' },
];

const defaultState = {
  captionJobName: '',
  description: '',
  selectedLanguage: '',
  filesToUpload: [],
};

const noop = () => {
  /*noop*/
};

function FilesPanel({ loadHelpPanelContent, updateDirty = noop, readOnlyWithErrors = false }) {
  const [selectedLanguage, setSelectedLanguage] = React.useState({ label: "Select a Language", value: "lang-none" });
  const [fileUploadProgress, setFileUploadProgress] = React.useState(0);
  const [fileUploadProgressLabel, setFileUploadProgressLabel] = React.useState("");
  const [fileUploadProgressDiv, setFileUploadProgressDiv] = React.useState("hidden");
  const [captionJobNameError, setCaptionJobNameError] = React.useState("");
  const [languageError, setLanguageError] = React.useState("");
  const [disableName, setDisableName] = React.useState(false);
  const [disableDescription, setDisableDescription] = React.useState(false);
  const [disableLanguage, setDisableLanguage] = React.useState(false);
  const [disableFile, setDisableFile] = React.useState(false);
  const [fileError, setFileError] = React.useState("");
  const [submitting, setSubmitting] = useState(false);
  const [FilesData, setFilesData] = useState(
    !readOnlyWithErrors ? defaultState : { ...defaultState }
  );
  const navigate = useNavigate();
  const submitButton = async () => {
    var isLanguageEmpty = false;
    var isNameEmpty = false;
    var isFileEmpty = false;
    // console.log("Submit Form");
    // Validate fields are filled out. 
    // (These if elses can be re-written when I've had more than 4 hours of sleep).
    // Could use the JOI library or something
    if (FilesData.captionJobName == null || FilesData.captionJobName == "") {
      // console.log("CAPPY IS EMPTY");
      setCaptionJobNameError("You must enter a valid name");
      isNameEmpty = false;
      // return;
    } else if (FilesData.captionJobName.length > 0) {

      // Check if the name exists in DDB already
      const existingCaptionCornerModel = await API.graphql({
        query: listCaptionCornerModels,
        variables: {
          filter: { name: { eq: FilesData.captionJobName, } }
        }
      });

      // console.log(existingCaptionCornerModel);
      if (existingCaptionCornerModel != null & existingCaptionCornerModel.data.listCaptionCornerModels.items.length > 0) {
        // console.log(fileProgressBarTotal);
        setCaptionJobNameError("You must enter a UNIQUE name");
        isNameEmpty = false;
      } else {
        setCaptionJobNameError("");
        isNameEmpty = true;
      }
    }
    // console.log(selectedLanguage.value);
    // console.log(selectedLanguage);

    // Language hasn't been selected
    if (selectedLanguage.value == "lang-none") {
      // console.log("Language hasn't been selected");
      setLanguageError("You must select a language");
      isLanguageEmpty = false;
      // return;
    } else {
      // console.log("Language has been selected");
      setLanguageError("");
      isLanguageEmpty = true;
    }

    if (FilesData.filesToUpload.length == 0) {
      // console.log("No files have been selected");
      setFileError("Please select at least one file");
      isFileEmpty = false;
      // return;
    } else if (FilesData.filesToUpload.length > 0) {
      for (let i = 0; i < FilesData.filesToUpload.length; i++) {
        // Get the file extension
        const extensionArray = FilesData.filesToUpload[i].name.split(".");
        const extension = extensionArray[extensionArray.length - 1].toLowerCase();
        // console.log("EXT " + extension);
        // Check if it's a valid extension
        if (extension == 'mp3' || extension == 'mp4' ||
          extension == 'wav' || extension == 'flac' ||
          extension == 'ogg' || extension == 'amr' || extension == 'webm') {
          setFileError("");
          isFileEmpty = true;
        } else {
          setFileError("Please select only VALID file formats: MP3, MP4, WAV, FLAC, AMR, OGG, and WebM.");
          isFileEmpty = false;
          break;
        }
      }
    }

    // If all is well then start the uploads
    if (isFileEmpty && isLanguageEmpty && isNameEmpty) {


      var email = "";
      await Auth.currentAuthenticatedUser().then((user) => email = user.attributes.email);
      const emailsplit = email.split("@");

      console.log(emailsplit);
      setFileUploadProgressDiv("");
      //Set the submit button to loading
      setSubmitting(true);
      //Disable the other fields
      setDisableName(true);
      setDisableDescription(true);
      setDisableLanguage(true);
      setDisableFile(true);
      var statsid = 0;
      // Check if the DDB entry for the user exists in the Stats table. If not create it.
      const allCaptionCornerStatsModels = await API.graphql({
        query: captionCornerStatsModelsByUsername,
        variables: {
          username: emailsplit[0]
        }
      });
      // console.log(allCaptionCornerStatsModels);
      if (allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items.length > 0) {
        // Set the statsId
        statsid = allCaptionCornerStatsModels.data.captionCornerStatsModelsByUsername.items[0].id
        console.log("HAS AN ENTRY");
      } else {



        console.log("NO ENTRY");
        const date = new Date();
        // create the entry
        const newCaptionCornerStatsModel = await API.graphql({
          query: createCaptionCornerStatsModel,
          variables: {
            input: {
              'totalCostSavings': 0,
              'totalDuration': 0,
              'totalFiles': 0,
              'createDate': date,
              'updateDate': date,
              'totalmp3': 0,
              'totalmp4': 0,
              'totalwav': 0,
              'totalflac': 0,
              'totalogg': 0,
              'totalamr': 0,
              'totalwebm': 0,
              'username': emailsplit[0]
            }
          }
        });
        statsid = newCaptionCornerStatsModel.data.createCaptionCornerStatsModel.id;
        //console.log("STATS " + statsid);
      }

      var fileToUploadKey;
      // This loop will upload the files one at a time
      for (let i = 0; i < FilesData.filesToUpload.length; i++) {

        const date = new Date();
        // Get the file extension
        const extensionArray = FilesData.filesToUpload[i].name.split(".");
        // console.log(extensionArray);
        const extension = extensionArray[extensionArray.length - 1];
        // console.log(extension);
        // Insert a DDB entry per file uploaded
        const newCaptionCornerModel = await API.graphql({
          query: createCaptionCornerModel,
          variables: {
            input: {
              "name": FilesData.captionJobName + (i > 0 ? i : ''),
              "description": FilesData.description,
              "filename": FilesData.filesToUpload[i].name,
              "s3Location": "",
              "statusMessage": "Pending",
              "statusType": "pending",
              "type": "cappy",
              "srt": "",
              "vtt": "",
              "language": selectedLanguage.value,
              "languageToDisplay": selectedLanguage.label,
              "costSavings": 0,
              "createDate": date,
              "updateDate": date,
              "delete": "",
              "duration": 0,
              "extension": extension
              // console.log("KEY: " + fileToUploadKey);
            }
            // console.log(progress);
            // console.log(fileProgressBarTotal);
          }
        });

        var ddbId = JSON.stringify(newCaptionCornerModel.data.createCaptionCornerModel.id);
        // console.log("ID: " + ddbId);
        const key = decodeURIComponent(ddbId.replace(/['"]+/g, ''));
        // console.log(key);
        // console.log("Uploading: " + FilesData.filesToUpload[i].name);
        setFileUploadProgressLabel("Uploading: " + FilesData.filesToUpload[i].name);
        fileToUploadKey = await Storage.put(key + "/" + FilesData.filesToUpload[i].name, FilesData.filesToUpload[i], {
          level: "private",
          metadata: { recordId: ddbId, statsId: statsid },
          progressCallback(progress) {
            // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            // console.log('Uploaded: ' + ((progress.loaded / progress.total) * 100));
            setFileUploadProgress((progress.loaded / progress.total) * 100);
            // Update the correct div with the progress
          },
        });
        // console.log(JSON.stringify(fileToUploadKey, null, 2));
        // console.log("LANG: " + selectedLanguage.value);
        // console.log("DURATION: " + FilesData.filesToUpload[i].name.duration)
      }
      setSubmitting(false);
      navigate("/")
    }
  };



  useEffect(() => {
    const isDirty = JSON.stringify(FilesData) !== JSON.stringify(defaultState);
    updateDirty(isDirty);
  }, [FilesData]);

  const onChange = (attribute, value) => {
    if (readOnlyWithErrors) {
      return;
    }

    const newState = { ...FilesData };
    newState[attribute] = value;
    setFilesData(newState);
  };

  const getErrorText = errorMessage => {
    return readOnlyWithErrors ? errorMessage : undefined;
  };

  const functionFileErrors = readOnlyWithErrors
    ? [null, 'Invalid test event structure', 'Invalid test event structure']
    : undefined;

  return (
    <Container
      header={<Header variant="h2">Options</Header>}
    >
      <SpaceBetween size="l">
        <FormField
          label="Name"
          // description="Name your caption job"
          errorText={captionJobNameError}
          i18nStrings={{ errorIconAriaLabel: 'Error' }}
        >
          <Input
            disabled={disableName}
            value={FilesData.captionJobName}
            ariaRequired={true}
            placeholder="Capybara"
            onChange={({ detail: { value } }) => onChange('captionJobName', value)}
          />
        </FormField>
        <FormField
          label="Description"
          // description="Add an optional description"
          errorText={getErrorText('You must specify a description.')}
          i18nStrings={{ errorIconAriaLabel: 'Error' }}
        >
          <Input
            disabled={disableDescription}
            value={FilesData.description}
            ariaRequired={true}
            placeholder="Useful description goes here"
            onChange={({ detail: { value } }) => onChange('description', value)}
          />
        </FormField>
        <FormField
          label="Language"
          description="Choose the language of the input file."
          errorText={languageError}
          i18nStrings={{ errorIconAriaLabel: 'Error' }}
        >
          <Select
            disabled={disableLanguage}
            selectedOption={selectedLanguage}
            options={LANGUAGE_OPTIONS}
            onChange={({ detail }) =>
              setSelectedLanguage(detail.selectedOption)
            }
          // onChange={({ detail: { selectedOption } }) => onChange('detail.selectedLanguage.value', selectedOption)}
          />
        </FormField>
        <div hidden={disableFile}>
          <FormField
            label="Files to process"
            description="Upload your files here. Files will be uploaded one at a time"
          >
            <FileUpload
              // multiple={false}
              multiple={true}
              showFileSize={true}
              showFileLastModified={true}
              accept="audio/mp3, video/mp4, audio/wav, audio/flac, audio/AMR, audio/ogg, video/webm"
              value={FilesData.filesToUpload}
              tokenLimit={3}
              onChange={({ detail: { value } }) => onChange('filesToUpload', value)}
              errorText={fileError}
              fileErrors={functionFileErrors}
              constraintText="Valid file formats: MP3, MP4, WAV, FLAC, AMR, OGG, and WebM."
              i18nStrings={{
                uploadButtonText: multiple => (multiple ? 'Choose files' : 'Choose file'),
                dropzoneText: multiple => (multiple ? 'Drop files to upload' : 'Drop file to upload'),
                removeFileAriaLabel: fileIndex => `Remove file ${fileIndex + 1}`,
                limitShowFewer: 'Show fewer files',
                limitShowMore: 'Show more files',
                errorIconAriaLabel: 'Error',
              }}
            />
          </FormField>
        </div>
        <div hidden={fileUploadProgressDiv}>
          <ProgressBar
            // status='{fileUploadProgressSuccess}'
            value={fileUploadProgress}
            additionalInfo=""
            description=""
            label={fileUploadProgressLabel}
          />
        </div>
        <div>
          <form onSubmit={event => event.preventDefault()}>
            <Form>
              <Button variant="link" href='/'>
                Cancel
              </Button>
              <Button variant="primary" onClick={submitButton} loading={submitting}>
                Submit
              </Button>
            </Form>
          </form>
        </div>
      </SpaceBetween>
    </Container>
  );
}




function BaseFormContent({ content, onCancelClick, errorText = null }) {
  // const [submitting, setSubmitting] = useState(false);


  const submit = async () => {
    // console.log("Submit Form");
    //Validate fields are filled out

    // console.log(FilesData.filesToUpload);
    // const result = await Storage.put("test.txt", "Hello");
    // console.log(result);
    try {
      //Set the submit button to loading
      // setSubmitting(true);

      //If succesful then go back to main page
      //add notification maybe

    } catch (e) {
      //Catch exceptions
    } finally {
      // Set the submit button back to normal
      // setSubmitting(false);
    }
  };


  return (
    // <form onSubmit={event => event.preventDefault()}>
    <Form
    // actions={
    //   <SpaceBetween direction="horizontal" size="xs">
    //     <Button variant="link" onClick={onCancelClick}>
    //       Cancel
    //     </Button>
    //     <Button data-testid="submit" variant="primary" onClick={submit} loading={submitting}>
    //       Submit
    //     </Button>
    //   </SpaceBetween>
    // }
    // errorText={errorText}
    // errorIconAriaLabel="Error"
    >
      {content}
    </Form>
    // </form>
  );
}

export function FormContent({ loadHelpPanelContent }) {
  return (
    <BaseFormContent
      content={
        <SpaceBetween size="l">
          <FilesPanel loadHelpPanelContent={loadHelpPanelContent} />
        </SpaceBetween>
      }
    />
  );
}

export const FormLimitedContent = ({ loadHelpPanelContent, updateDirty, onCancelClick }) => {
  return (
    <BaseFormContent
      onCancelClick={onCancelClick}
      content={<FilesPanel loadHelpPanelContent={loadHelpPanelContent} updateDirty={updateDirty} />}
    />
  );
};

export const FormContentReadOnlyWithErrors = ({ loadHelpPanelContent }) => {
  return (
    <BaseFormContent
      content={
        <SpaceBetween size="l">
          <FilesPanel loadHelpPanelContent={loadHelpPanelContent} readOnlyWithErrors={true} />
        </SpaceBetween>
      }
      errorText="Something went wrong."
    />
  );
};
