/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "f50ed2127003412ab852057e2fd0a6a6",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "f50ed2127003412ab852057e2fd0a6a6",
            "region": "us-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://naxwd66llffvdndcbkdl33esnm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-bjtplsghrzeldhvzgc2fha22my",
    "aws_cognito_identity_pool_id": "us-west-2:0241a893-03aa-4063-9e1c-f138e57091b5",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_xfUEbNUax",
    "aws_user_pools_web_client_id": "2ojbnp92ngc9umqshr2u2c5q3e",
    "oauth": {
        "domain": "captioncornerf7c69430-f7c69430-staging.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.captioncorner.ww-fieldenablement.aws.dev/,http://localhost:3000/",
        "redirectSignOut": "https://www.captioncorner.ww-fieldenablement.aws.dev/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "captioncorner4e47ea7b4ff349cfafe46a5ac220c561215810-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
