import React, { useRef, useState } from 'react';
import { BreadcrumbGroup, ContentLayout, Header } from '@amzn/awsui-components-react';
import { CustomAppLayout, Navigation, Notifications } from '../commons/common-components';
import { uploadFilesBreadcrumbs } from './uploadfilescommon/breadcrumbs';
import { FormContent } from './uploadfilescomponents/form-content';
import '../styles/form.scss';
import { Analytics } from "aws-amplify";

Analytics.autoTrack('pageView', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: 'UploadPage',
  type: 'SPA',
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  }
});

const Breadcrumbs = () => (
  <BreadcrumbGroup items={uploadFilesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

function FormHeader() {
  return (
    <Header
      variant="h1"
      description="Please fill out the following fields."
    >
      Upload File(s)
    </Header>
  );
}

const UploadFilesPage = () => {
  const appLayout = useRef();

  //   const loadHelpPanelContent = index => {
  //     setToolsIndex(index);
  //     setToolsOpen(true);
  //     appLayout.current?.focusToolsClose();
  //   };

  return (
    // <div>This is better than the whole </div>
    <CustomAppLayout
      //   ref={appLayout}
      contentType="form"
      content={
        <ContentLayout header={<FormHeader />}>
          <FormContent loadHelpPanelContent={""} />
        </ContentLayout>
      }
      navigationHide={true}
      toolsHide={true}
      breadcrumbs={<Breadcrumbs />}
      // navigation={<Navigation activeHref="/uploadfiles" />}
      // tools={ToolsContent[toolsIndex]}
      // toolsOpen={toolsOpen}
      // onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      notifications={<Notifications />}
    />
  );
}

export { UploadFilesPage };