/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCaptionCornerModel = /* GraphQL */ `
  mutation CreateCaptionCornerModel(
    $input: CreateCaptionCornerModelInput!
    $condition: ModelCaptionCornerModelConditionInput
  ) {
    createCaptionCornerModel(input: $input, condition: $condition) {
      id
      name
      type
      description
      filename
      s3Location
      statusMessage
      statusType
      srt
      vtt
      language
      languageToDisplay
      costSavings
      createDate
      updateDate
      delete
      duration
      extension
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCaptionCornerModel = /* GraphQL */ `
  mutation UpdateCaptionCornerModel(
    $input: UpdateCaptionCornerModelInput!
    $condition: ModelCaptionCornerModelConditionInput
  ) {
    updateCaptionCornerModel(input: $input, condition: $condition) {
      id
      name
      type
      description
      filename
      s3Location
      statusMessage
      statusType
      srt
      vtt
      language
      languageToDisplay
      costSavings
      createDate
      updateDate
      delete
      duration
      extension
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCaptionCornerModel = /* GraphQL */ `
  mutation DeleteCaptionCornerModel(
    $input: DeleteCaptionCornerModelInput!
    $condition: ModelCaptionCornerModelConditionInput
  ) {
    deleteCaptionCornerModel(input: $input, condition: $condition) {
      id
      name
      type
      description
      filename
      s3Location
      statusMessage
      statusType
      srt
      vtt
      language
      languageToDisplay
      costSavings
      createDate
      updateDate
      delete
      duration
      extension
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCaptionCornerStatsModel = /* GraphQL */ `
  mutation CreateCaptionCornerStatsModel(
    $input: CreateCaptionCornerStatsModelInput!
    $condition: ModelCaptionCornerStatsModelConditionInput
  ) {
    createCaptionCornerStatsModel(input: $input, condition: $condition) {
      id
      totalCostSavings
      totalDuration
      totalFiles
      createDate
      updateDate
      totalmp3
      totalmp4
      totalwav
      totalflac
      totalogg
      totalamr
      totalwebm
      username
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCaptionCornerStatsModel = /* GraphQL */ `
  mutation UpdateCaptionCornerStatsModel(
    $input: UpdateCaptionCornerStatsModelInput!
    $condition: ModelCaptionCornerStatsModelConditionInput
  ) {
    updateCaptionCornerStatsModel(input: $input, condition: $condition) {
      id
      totalCostSavings
      totalDuration
      totalFiles
      createDate
      updateDate
      totalmp3
      totalmp4
      totalwav
      totalflac
      totalogg
      totalamr
      totalwebm
      username
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCaptionCornerStatsModel = /* GraphQL */ `
  mutation DeleteCaptionCornerStatsModel(
    $input: DeleteCaptionCornerStatsModelInput!
    $condition: ModelCaptionCornerStatsModelConditionInput
  ) {
    deleteCaptionCornerStatsModel(input: $input, condition: $condition) {
      id
      totalCostSavings
      totalDuration
      totalFiles
      createDate
      updateDate
      totalmp3
      totalmp4
      totalwav
      totalflac
      totalogg
      totalamr
      totalwebm
      username
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
