import React, { useId } from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';

export function useDisclaimerFlashbarItem(onDismiss: (id: string) => void): FlashbarProps.MessageDefinition | null {
  const id = useId();

  if (!process.env.EXTERNAL_SITE) {
    return null;
  }

  return {
    type: 'info',
    dismissible: true,
    dismissLabel: 'Dismiss message',
    onDismiss: () => onDismiss(id),
    statusIconAriaLabel: 'info',
    content: (
      <>
        This demo is an example of Cloudscape Design System patterns and components, and may not reflect the current
        patterns and components of AWS services.
      </>
    ),
    id,
  };
}
